<template>
  <button
    class="button-container btn btn-outline-secondary"
    @click="showModalAgenteSistema"
  >
    <i class="fas fa-cog"></i>
  </button>
</template>

<script>
export default {
  methods: {
    showModalAgenteSistema() {
      this.$modal.show("modal-sistema-agente");
    }
  }
};
</script>

<style lang="scss" scoped>
.button-container {
  border: 1px solid #e4e6ef;
  outline: none;
  color: #e3e4ed;
  background-color: transparent;
  border-radius: 0.42rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 38px;
  &.btn i {
    padding: 0 !important;
  }
}
</style>
